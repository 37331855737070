<template>
  <v-card
    flat
    class="xw-full xmax-w-[1200px] xmx-auto xshadow-lg xmin-h-screen"
  >
    <v-progress-linear
      indeterminate
      :height="10"
      class="my-1"
      v-if="fetching"
      color="primary"
    ></v-progress-linear>
    <v-sheet outlined class="xmb-2 xp-3">
      <EachSetting
        title="Set Bot Response Source"
        subtitle="You can choose which bot responses will be used for this team."
      >
        <template v-slot:left>
          <strong> Team and Bot </strong>
          <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
            By default, {{ appName }} uses both the responses from
            {{ appName }} and your specified team's bot responses randomly.
          </div>
          <strong> Team Only </strong>
          <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
            {{ appName }} will use responses from your team's customized bot. If
            the team has not set responses for a particular command,
            {{ appName }} will use its own bot responses.
          </div>
          <strong> Bot Only </strong>
          <div class="xborder-l-4 xmy-1 xborder-purple-600 xp-2">
            {{ appName }} will use responses from the app.
          </div>
        </template>
        <template v-slot:right>
          <v-autocomplete
            hide-details="auto"
            dense
            :disabled="!$can.and('manage_bot_responses')"
            v-model="response_source"
            outlined
            @change="handleChange"
            :items="options"
            item-text="text"
            item-value="value"
          ></v-autocomplete>
        </template>
      </EachSetting>
    </v-sheet>
    <v-expansion-panels flat v-model="panel" multiple>
      <EachBotType
        :title="item.title"
        :type="item.type"
        v-for="(item, i) in panels"
        :key="i"
        class="xpx-2"
      ></EachBotType>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import EachBotType from "../../../AppDashboard/Settings/components/EachBotType.vue";
import EachSetting from "../../../AppDashboard/Settings/components/EachSetting.vue";
export default {
  components: { EachBotType, EachSetting },
  data() {
    return {
      panel: [0, 1, 2, 3],
      panels: [
        { title: "Enter the bot response when a user clocks /in", type: "in" },
        {
          title: "Enter the bot response when a user clocks /brb",
          type: "brb",
        },
        {
          title: "Enter the bot response when a user clocks /back",
          type: "back",
        },
        {
          title: "Enter the bot response when a user clocks /out",
          type: "out",
        },
      ],
      options: [
        {
          text: "Team and Bot",
          value: "both",
        },
        { text: "Team Only", value: "team" },
        { text: "Bot Only", value: "app" },
      ],
    };
  },
  computed: {
    ...mapGetters("bots", ["fetching"]),
    ...mapGetters("workspaceconfigs", ["getByKey"]),
    team_id() {
      return this.$route.params.id;
    },
    response_source: {
      get() {
        return this.getByKey("response_source", "both");
      },
      set(val) {
        this.set_config({ key: "response_source", value: val });
      },
    },
  },
  created() {
    this.fetchBotResponses(this.team_id);
  },
  mounted() {
    this.fetchWorkspaceConfigs(this.team_id);
  },
  methods: {
    ...mapMutations("workspaceconfigs", ["set_config"]),
    ...mapActions("bots", ["fetchBotResponses"]),
    ...mapActions("workspaceconfigs", [
      "updateWorkspaceConfig",
      "fetchWorkspaceConfigs",
    ]),
    handleChange(val) {
      this.updateWorkspaceConfig({
        id: this.team_id,
        payload: { key: "response_source", value: val },
        cb: () => {
          this.appToast("The bot response source has been updated.", "success");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
